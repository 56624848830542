<template>
    <el-dialog title="订单日志"
               :visible="visible"
               :close-on-click-modal="false"
               width="1100px"
               @close="close">
        <grid-table ref="gridTable"
                    :tableConfig="tableConfig"
                    :showCheckboxColumn="false">
        </grid-table>
    </el-dialog>
</template>
<script>
    export default {
        name: "TradeLog",
        data() {
            return {
                visible: false,
                tableConfig: {
                    url: "/api/log/getLogOrderOperationList",
                    columns: [
                        {
                            label: "操作人",
                            prop: "UserName",
                            type: "text",
                            width: "130px",
                        },
                        {
                            label: "操作内容",
                            prop: "Content",
                            type: "text",
                            width: "400px",
                        },
                        {
                            label: "操作时间",
                            prop: "AppendTimeStr",
                            type: "text",
                            width: "200px",
                        },
                        {
                            label: "IP地址",
                            prop: "IPAddress",
                            type: "text",
                            width: "200px",
                        },
                    ],
                    searchForm: {
                        data: {
                            TradeID: 0,
                        },
                    },
                },
            };
        },
        methods: {
            init(tradeID) {
                this.visible = true;
                this.$nextTick(() => {
                    this.tableConfig.searchForm.data.TradeID = tradeID;
                    this.$refs.gridTable.fetchData();
                });
            },
            close() {
                this.$refs.gridTable.tableData = [];
                this.visible = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>
